<template>
  <div id="preview">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title
          >{{ ReportType }} {{ pageDescription }} -
          {{ row.LomName }}</v-toolbar-title
        >

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text>
        <v-container class="py-0" v-if="StartupLoadingFlag">
          <v-skeleton-loader
            v-bind="attrs"
            type="table-tbody"
          ></v-skeleton-loader>
        </v-container>
        <v-container class="py-0" v-if="ResultFlag">
          <v-row wrap v-if="recordData.ReasonHistory != ''">
            <v-col cols="12" sm="12" lg="12" md="12">
              <h5 class="blue--text text--lighten">Approval Remarks</h5>
              <p
                class="font-size-h6 mt-3"
                v-html="recordData.ReasonHistory"
              ></p>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">LO Name</h5>
              <p class="preview-content">{{ recordData.LomName }} <br /></p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Zone</h5>
              <p class="preview-content">{{ recordData.ZoneName }} <br /></p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Region</h5>
              <p class="preview-content">{{ recordData.RegionName }} <br /></p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Area</h5>
              <p class="preview-content">{{ recordData.AreaName }} <br /></p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Approval Status</h5>
              <v-chip :color="recordData.TrainingStatusColor" draggable dark>{{
                recordData.TrainingStatusTxt
              }}</v-chip>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Training Status</h5>
              <v-chip :color="recordData.CompletedStatusColor" draggable dark>{{
                recordData.CompletedStatusTxt
              }}</v-chip>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Completed Date</h5>
              <p class="preview-content">
                {{ recordData.CompletedDateTxt }} <br />
              </p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">
                {{ ReportType }} Uploaded Report
              </h5>
              <template v-if="recordData.ReportDownloadFlag1">
                <v-btn
                  :href="recordData.ReportAssetPath1"
                  target="_blank"
                  color="red"
                  small
                  class="text-white"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark>mdi-file-pdf</v-icon>Download
                </v-btn>
              </template>
              <template v-else>
                <h5 class="text-danger">Report not uploaded</h5>
              </template>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col cols="12" sm="12" lg="12" md="12">
              <h5 class="blue--text text--lighten">
                {{ ReportType }} Training Documents
              </h5>
              <template v-if="TrainingDocuments.length > 0">
                <v-data-iterator
                  :items="TrainingDocuments"
                  :items-per-page="-1"
                  hide-default-footer
                >
                  <template v-slot:header> </template>

                  <template v-slot:default="props">
                    <v-row>
                      <v-col
                        v-for="document in props.items"
                        :key="document.DocumentId"
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                      >
                        <v-card raised hover ripple elevation="25">
                          <v-card-text class="text-center">
                            <viewer rebuild :images="document.Images">
                              <img
                                v-for="src in document.Images"
                                width="100"
                                height="100"
                                :src="src"
                                :key="src"
                              />
                            </viewer>
                            <h3 class="text-primary">
                              {{ document.DocumentTypeName }}
                            </h3>
                            <h5>
                              {{ document.DocumentDateTxt }}
                            </h5>
                            <h5 class="text-primary">
                              {{ document.DocumentDescription }}
                            </h5>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </template>
              <template v-else>
                <h5 class="text-danger">No documents uploaded</h5>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closePrompt"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";

export default {
  mixins: [common],
  components: {},
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    row: {
      required: true,
    },
    category: {
      required: true,
    },
    pageDescription: {
      type: String,
    },
  },
  data() {
    return {
      recordData: [],
      TrainingDocuments: [],
      ResultFlag: false,
      StartupLoadingFlag: false,

      ReportType: "",
    };
  },
  computed: {},
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    recordData: function () {
      console.log("watch recordData");
      this.StartupLoadingFlag = false;
      var tr = this.recordData;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      this.ResultFlag = n1 > 0 ? true : false;
      this.ReportType = n1 > 0 ? tr.CategoryName : "";
      this.TrainingDocuments = n1 > 0 ? tr.TrainingDocuments : [];
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog");
    },
    validate() {
      console.log("validate is called");
      var result = this.$refs.form.validate();
      console.log("result=" + result);
      if (result) {
        this.addRecordDetails();
      } else {
        console.log("some errors occured");
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.getRecordDetails();
    },
    getRecordDetails() {
      console.log("getRecordDetails called");
      this.ResultFlag = false;
      this.StartupLoadingFlag = true;
      this.TrainingDocuments = [];
      var tr = this.row;
      console.log({ tr });
      var id = tr.LomTrainingId;
      console.log(id);
      var category = tr.TrainingCategoryId;
      console.log({ category });
      var list_url = "api/lom-training/show";
      var where = {
        Category: category,
        LoTraining: id,
        Lo: tr.LomId,
      };
      console.log("list_url=" + list_url + ", where=" + JSON.stringify(where));
      this.getTableData("recordData", list_url, where);
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
